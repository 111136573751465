import {
  COOKIE_OPTS,
  HTTP_ONLY_COOKIE_OPTS,
} from '~/constants/cookies.constants'

export const useAuthCookie = () => {
  const jwtData = useCookie('jwt-data', COOKIE_OPTS)
  const jwtSignature = useCookie('jwt-signature', HTTP_ONLY_COOKIE_OPTS)
  const refreshToken = useCookie('jwt-refresh-token', HTTP_ONLY_COOKIE_OPTS)
  const deviceId = useCookie('device-id')

  const setJwtCookies = (
    newAccessToken: string,
    newRefreshToken: string
  ): void => {
    const [jwtHeaderResp, jwtDataResp, jwtSignatureResp] =
      newAccessToken.split('.')

    jwtData.value = `${jwtHeaderResp}.${jwtDataResp}`
    jwtSignature.value = jwtSignatureResp
    refreshToken.value = newRefreshToken
  }

  return {
    jwtData,
    jwtSignature,
    refreshToken,
    deviceId,
    setJwtCookies,
  }
}
