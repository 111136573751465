
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about03Gm_4586gAcqy0vptol8xNaQK28Q_WFcIPPBKgeDfnsEMeta } from "/app/src/pages/about.vue?macro=true";
import { default as indexiNtA_znKj_zn_45wsEgsmLpyYSVS7XVZ24_KIDRjqK_10Meta } from "/app/src/pages/index.vue?macro=true";
import { default as indexV9NYRlDOgGVTv1yW1gh55wCVANF_45xPEDyabg6X6TOu4Meta } from "/app/src/pages/cart/index.vue?macro=true";
import { default as cartFX9xU1GBF1m0OSqRa6sBtUaJf0EFynAUuW15AY_45BizgMeta } from "/app/src/pages/sandbox/cart.vue?macro=true";
import { default as indexdchlHxGURTIQjm4iJwEpLyt9pjJL4R7fm1HeXjyFdLYMeta } from "/app/src/pages/sandbox/index.vue?macro=true";
import { default as indexDJa7CcLRgI43_ZO_45HkmNNYh56CJF3XCOEKWEVSQXCKYMeta } from "/app/src/pages/swagger/index.vue?macro=true";
import { default as layout_45PmNEAGDKBd6LXjuvsGbY536Ia453ewET2BivZxCmrUMeta } from "/app/src/pages/preview/layout.vue?macro=true";
import { default as storesAmNQ5oy0M8dU_qjr1n8dYxBaL0cwQ09yikZA_45rcec1kMeta } from "/app/src/pages/sandbox/stores.vue?macro=true";
import { default as vue_45usetcv7ITswAqMpovt9P0b7M_45mOGjc_45A6PMF0ZvUcHLLnsMeta } from "/app/src/pages/sandbox/vue-use.vue?macro=true";
import { default as languageVpM7K5lB9po9kRl9CUjNC8k5JHKyallttQYgZNmZ4L8Meta } from "/app/src/pages/sandbox/language.vue?macro=true";
import { default as empty_45cartZI5UVu2TkMLfy_cOoMn5K3dAzBGjS0cgvXh_iJAVB8wMeta } from "/app/src/pages/sandbox/empty-cart.vue?macro=true";
import { default as layout_45bffirUcVo0FhxflMhb3hDV0C39NngVfOOMQ3hAkrFF1lXEMeta } from "/app/src/pages/sandbox/layout-bff.vue?macro=true";
import { default as notificationRYB_452OT9pO_0RtyMaXdsz6sTQiWbmDpsslw41X9Gk9oMeta } from "/app/src/pages/sandbox/notification.vue?macro=true";
import { default as _91id_93xAz78pD1FAVf5I4ERAzTzU3AjArYyOjKPcQn2BQ4n14Meta } from "/app/src/pages/happening/events/[id].vue?macro=true";
import { default as ui_45componentsn1inDUr5ZxOzxgbXioNRrVic6pjYjS0xs_451tSFANeK4Meta } from "/app/src/pages/sandbox/ui-components.vue?macro=true";
import { default as _91id_93S0rWpwgLlAkcU6Tsa6oTPDLLpiBE1GYZ9MY8L0HPM2AMeta } from "/app/src/pages/happening/classes/[id].vue?macro=true";
import { default as cart_45line_45itemLfE1zTyFsN3ghtqkp2goZqOEvPUIJ1AJLmU1F28Tf6gMeta } from "/app/src/pages/sandbox/cart-line-item.vue?macro=true";
import { default as _91id_93kNtgs83iCkrs2kBMBILh36VbYcRiWG3uket8JBPi_45SMMeta } from "/app/src/pages/happening/services/[id].vue?macro=true";
import { default as indexn5yo4liKbJg8bBpy5fGvAcrZ7q_45FmM2b6E9GTGq3jOcMeta } from "/app/src/pages/happening/services/index.vue?macro=true";
import { default as device_45detectionAKaudVYaZJLY08zIdHPIupX04veTEfX0OLfC39gXA5cMeta } from "/app/src/pages/sandbox/device-detection.vue?macro=true";
import { default as indexaTUzk0gdcKFLEHwzpWtgcHKLBlmvxdnBbrqdhKBMRgMMeta } from "/app/src/pages/preview/overlay/[id]/index.vue?macro=true";
import { default as api_45server_45renderedhr7jdHHT8cmy5w5gKYI5AyUJsTKqaxPahkf0MiPKQdcMeta } from "/app/src/pages/sandbox/api-server-rendered.vue?macro=true";
import { default as _91id_931bfxtPtAepb1NLBm21r6cxo5BgKUBPjaCu9H_82pMLMMeta } from "/app/src/pages/happening/events/cancel/[id].vue?macro=true";
import { default as useElementVisibilitymqsLf28es_Y69entTdQ5X_45fcwdDl6AF1nGhyZ_FlXtoMeta } from "/app/src/pages/sandbox/useElementVisibility.vue?macro=true";
import { default as _91id_93ARzMVWq6wWtXWYv6ECo_VA5_45AOrvpt9Fc3WzM01i_1EMeta } from "/app/src/pages/happening/classes/cancel/[id].vue?macro=true";
import { default as _91id_93Tw5_4549ub8tpUao8DsWmvWbU_45xm9FoZehPsazwQq0wagMeta } from "/app/src/pages/happening/services/cancel/[id].vue?macro=true";
import { default as _91id_93AQadbPYDvyGKUxaKhcY_45VP6C8TUMv33hnNJPMa2HIRYMeta } from "/app/src/pages/happening/services/reschedule/[id].vue?macro=true";
import { default as index1KA6_J20UF_B_gDsfWhtaFheh4oQKzXaEh69XTl4_4528Meta } from "/app/src/pages/preview/homepage/[[slugUrl]]/index.vue?macro=true";
import { default as _91_91subCategory_93_93YR_FNrT0ybd6_aAqbcufEvmJWPC__ehAX6NPvbn_45cCIMeta } from "/app/src/pages/pages/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue?macro=true";
import { default as _91_91subCategory_93_93BZaxJQku1jIJzA_Ayb6oglUsvXgZtB0FJRE8Q1v1MRwMeta } from "/app/src/pages/brands/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue?macro=true";
import { default as _91_91subCategory_93_93ubEMe7iUWNnBPsIAcNmeqVoAJUEOrFlyLzeuTBpy_45ZsMeta } from "/app/src/pages/preview/pages/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue?macro=true";
import { default as _91_91subCategory_93_9302srII0auEbnRmCOOEGfFGJMkYdf4_45FGJcjqv7TwrtgMeta } from "/app/src/pages/preview/brands/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue?macro=true";
import { default as _91_91subCategory_93_93y1R290oQrtxmj_45tVB0PwK3F94Z7L9DHq_I5NGd7KN5YMeta } from "/app/src/pages/sandbox/contentful/[[pageType]]/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/app/src/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexiNtA_znKj_zn_45wsEgsmLpyYSVS7XVZ24_KIDRjqK_10Meta || {},
    component: () => import("/app/src/pages/index.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/app/src/pages/cart/index.vue")
  },
  {
    name: "sandbox-cart",
    path: "/sandbox/cart",
    component: () => import("/app/src/pages/sandbox/cart.vue")
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/app/src/pages/sandbox/index.vue")
  },
  {
    name: "swagger",
    path: "/swagger",
    meta: indexDJa7CcLRgI43_ZO_45HkmNNYh56CJF3XCOEKWEVSQXCKYMeta || {},
    component: () => import("/app/src/pages/swagger/index.vue")
  },
  {
    name: "preview-layout",
    path: "/preview/layout",
    meta: layout_45PmNEAGDKBd6LXjuvsGbY536Ia453ewET2BivZxCmrUMeta || {},
    component: () => import("/app/src/pages/preview/layout.vue")
  },
  {
    name: "sandbox-stores",
    path: "/sandbox/stores",
    component: () => import("/app/src/pages/sandbox/stores.vue")
  },
  {
    name: "sandbox-vue-use",
    path: "/sandbox/vue-use",
    component: () => import("/app/src/pages/sandbox/vue-use.vue")
  },
  {
    name: "sandbox-language",
    path: "/sandbox/language",
    component: () => import("/app/src/pages/sandbox/language.vue")
  },
  {
    name: "sandbox-empty-cart",
    path: "/sandbox/empty-cart",
    component: () => import("/app/src/pages/sandbox/empty-cart.vue")
  },
  {
    name: "sandbox-layout-bff",
    path: "/sandbox/layout-bff",
    component: () => import("/app/src/pages/sandbox/layout-bff.vue")
  },
  {
    name: "sandbox-notification",
    path: "/sandbox/notification",
    component: () => import("/app/src/pages/sandbox/notification.vue")
  },
  {
    name: "happening-events-id",
    path: "/happening/events/:id()",
    meta: _91id_93xAz78pD1FAVf5I4ERAzTzU3AjArYyOjKPcQn2BQ4n14Meta || {},
    component: () => import("/app/src/pages/happening/events/[id].vue")
  },
  {
    name: "sandbox-ui-components",
    path: "/sandbox/ui-components",
    component: () => import("/app/src/pages/sandbox/ui-components.vue")
  },
  {
    name: "happening-classes-id",
    path: "/happening/classes/:id()",
    meta: _91id_93S0rWpwgLlAkcU6Tsa6oTPDLLpiBE1GYZ9MY8L0HPM2AMeta || {},
    component: () => import("/app/src/pages/happening/classes/[id].vue")
  },
  {
    name: "sandbox-cart-line-item",
    path: "/sandbox/cart-line-item",
    component: () => import("/app/src/pages/sandbox/cart-line-item.vue")
  },
  {
    name: "happening-services-id",
    path: "/happening/services/:id()",
    meta: _91id_93kNtgs83iCkrs2kBMBILh36VbYcRiWG3uket8JBPi_45SMMeta || {},
    component: () => import("/app/src/pages/happening/services/[id].vue")
  },
  {
    name: "happening-services",
    path: "/happening/services",
    meta: indexn5yo4liKbJg8bBpy5fGvAcrZ7q_45FmM2b6E9GTGq3jOcMeta || {},
    component: () => import("/app/src/pages/happening/services/index.vue")
  },
  {
    name: "sandbox-device-detection",
    path: "/sandbox/device-detection",
    component: () => import("/app/src/pages/sandbox/device-detection.vue")
  },
  {
    name: "preview-overlay-id",
    path: "/preview/overlay/:id()",
    meta: indexaTUzk0gdcKFLEHwzpWtgcHKLBlmvxdnBbrqdhKBMRgMMeta || {},
    component: () => import("/app/src/pages/preview/overlay/[id]/index.vue")
  },
  {
    name: "sandbox-api-server-rendered",
    path: "/sandbox/api-server-rendered",
    component: () => import("/app/src/pages/sandbox/api-server-rendered.vue")
  },
  {
    name: "happening-events-cancel-id",
    path: "/happening/events/cancel/:id()",
    meta: _91id_931bfxtPtAepb1NLBm21r6cxo5BgKUBPjaCu9H_82pMLMMeta || {},
    component: () => import("/app/src/pages/happening/events/cancel/[id].vue")
  },
  {
    name: "sandbox-useElementVisibility",
    path: "/sandbox/useElementVisibility",
    component: () => import("/app/src/pages/sandbox/useElementVisibility.vue")
  },
  {
    name: "happening-classes-cancel-id",
    path: "/happening/classes/cancel/:id()",
    meta: _91id_93ARzMVWq6wWtXWYv6ECo_VA5_45AOrvpt9Fc3WzM01i_1EMeta || {},
    component: () => import("/app/src/pages/happening/classes/cancel/[id].vue")
  },
  {
    name: "happening-services-cancel-id",
    path: "/happening/services/cancel/:id()",
    meta: _91id_93Tw5_4549ub8tpUao8DsWmvWbU_45xm9FoZehPsazwQq0wagMeta || {},
    component: () => import("/app/src/pages/happening/services/cancel/[id].vue")
  },
  {
    name: "happening-services-reschedule-id",
    path: "/happening/services/reschedule/:id()",
    meta: _91id_93AQadbPYDvyGKUxaKhcY_45VP6C8TUMv33hnNJPMa2HIRYMeta || {},
    component: () => import("/app/src/pages/happening/services/reschedule/[id].vue")
  },
  {
    name: "preview-homepage-slugUrl",
    path: "/preview/homepage/:slugUrl?",
    meta: index1KA6_J20UF_B_gDsfWhtaFheh4oQKzXaEh69XTl4_4528Meta || {},
    component: () => import("/app/src/pages/preview/homepage/[[slugUrl]]/index.vue")
  },
  {
    name: "pages-slugUrl-topCategory-category-subCategory",
    path: "/pages/:slugUrl?/:topCategory?/:category?/:subCategory?",
    meta: _91_91subCategory_93_93YR_FNrT0ybd6_aAqbcufEvmJWPC__ehAX6NPvbn_45cCIMeta || {},
    component: () => import("/app/src/pages/pages/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue")
  },
  {
    name: "brands-slugUrl-topCategory-category-subCategory",
    path: "/brands/:slugUrl?/:topCategory?/:category?/:subCategory?",
    meta: _91_91subCategory_93_93BZaxJQku1jIJzA_Ayb6oglUsvXgZtB0FJRE8Q1v1MRwMeta || {},
    component: () => import("/app/src/pages/brands/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue")
  },
  {
    name: "preview-pages-slugUrl-topCategory-category-subCategory",
    path: "/preview/pages/:slugUrl?/:topCategory?/:category?/:subCategory?",
    meta: _91_91subCategory_93_93ubEMe7iUWNnBPsIAcNmeqVoAJUEOrFlyLzeuTBpy_45ZsMeta || {},
    component: () => import("/app/src/pages/preview/pages/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue")
  },
  {
    name: "preview-brands-slugUrl-topCategory-category-subCategory",
    path: "/preview/brands/:slugUrl?/:topCategory?/:category?/:subCategory?",
    meta: _91_91subCategory_93_9302srII0auEbnRmCOOEGfFGJMkYdf4_45FGJcjqv7TwrtgMeta || {},
    component: () => import("/app/src/pages/preview/brands/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue")
  },
  {
    name: "sandbox-contentful-pageType-slugUrl-topCategory-category-subCategory",
    path: "/sandbox/contentful/:pageType?/:slugUrl?/:topCategory?/:category?/:subCategory?",
    meta: _91_91subCategory_93_93y1R290oQrtxmj_45tVB0PwK3F94Z7L9DHq_I5NGd7KN5YMeta || {},
    component: () => import("/app/src/pages/sandbox/contentful/[[pageType]]/[[slugUrl]]/[[topCategory]]/[[category]]/[[subCategory]].vue")
  }
]