
// @ts-nocheck
import locale_en_46json_0b63539c from "#nuxt-i18n/0b63539c";
import locale_en_46json_749c8ca0 from "#nuxt-i18n/749c8ca0";
import locale_en_46json_9e9dbd21 from "#nuxt-i18n/9e9dbd21";
import locale_en_46json_2d0385b0 from "#nuxt-i18n/2d0385b0";
import locale_id_46json_6838c3cc from "#nuxt-i18n/6838c3cc";
import locale_id_46json_ac212fa5 from "#nuxt-i18n/ac212fa5";
import locale_id_46json_7aff03a8 from "#nuxt-i18n/7aff03a8";
import locale_id_46json_9cd2861e from "#nuxt-i18n/9cd2861e";
import locale_th_46json_1045cf04 from "#nuxt-i18n/1045cf04";
import locale_th_46json_6e8cb44e from "#nuxt-i18n/6e8cb44e";
import locale_th_46json_73489705 from "#nuxt-i18n/73489705";
import locale_th_46json_6e6d4575 from "#nuxt-i18n/6e6d4575";
import locale_zh_46json_eb6b6151 from "#nuxt-i18n/eb6b6151";
import locale_zh_46json_874d68e9 from "#nuxt-i18n/874d68e9";
import locale_zh_46json_28fd522b from "#nuxt-i18n/28fd522b";
import locale_zh_46json_8f8930c0 from "#nuxt-i18n/8f8930c0";

export const localeCodes =  [
  "en",
  "id",
  "th",
  "zh"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46json_0b63539c",
      load: () => Promise.resolve(locale_en_46json_0b63539c),
      cache: true
    },
    {
      key: "locale_en_46json_749c8ca0",
      load: () => Promise.resolve(locale_en_46json_749c8ca0),
      cache: true
    },
    {
      key: "locale_en_46json_9e9dbd21",
      load: () => Promise.resolve(locale_en_46json_9e9dbd21),
      cache: true
    },
    {
      key: "locale_en_46json_2d0385b0",
      load: () => Promise.resolve(locale_en_46json_2d0385b0),
      cache: true
    }
  ],
  id: [
    {
      key: "locale_id_46json_6838c3cc",
      load: () => Promise.resolve(locale_id_46json_6838c3cc),
      cache: true
    },
    {
      key: "locale_id_46json_ac212fa5",
      load: () => Promise.resolve(locale_id_46json_ac212fa5),
      cache: true
    },
    {
      key: "locale_id_46json_7aff03a8",
      load: () => Promise.resolve(locale_id_46json_7aff03a8),
      cache: true
    },
    {
      key: "locale_id_46json_9cd2861e",
      load: () => Promise.resolve(locale_id_46json_9cd2861e),
      cache: true
    }
  ],
  th: [
    {
      key: "locale_th_46json_1045cf04",
      load: () => Promise.resolve(locale_th_46json_1045cf04),
      cache: true
    },
    {
      key: "locale_th_46json_6e8cb44e",
      load: () => Promise.resolve(locale_th_46json_6e8cb44e),
      cache: true
    },
    {
      key: "locale_th_46json_73489705",
      load: () => Promise.resolve(locale_th_46json_73489705),
      cache: true
    },
    {
      key: "locale_th_46json_6e6d4575",
      load: () => Promise.resolve(locale_th_46json_6e6d4575),
      cache: true
    }
  ],
  zh: [
    {
      key: "locale_zh_46json_eb6b6151",
      load: () => Promise.resolve(locale_zh_46json_eb6b6151),
      cache: true
    },
    {
      key: "locale_zh_46json_874d68e9",
      load: () => Promise.resolve(locale_zh_46json_874d68e9),
      cache: true
    },
    {
      key: "locale_zh_46json_28fd522b",
      load: () => Promise.resolve(locale_zh_46json_28fd522b),
      cache: true
    },
    {
      key: "locale_zh_46json_8f8930c0",
      load: () => Promise.resolve(locale_zh_46json_8f8930c0),
      cache: true
    }
  ]
}

export const vueI18nConfigs = [
  () => import("#nuxt-i18n/a5f4dfa6" /* webpackChunkName: "config_index_46ts_a5f4dfa6" */)
]

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "./locale-detector.ts",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: false,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      name: "english",
      files: [
        "/app/i18n/locales/en.json",
        "/app/i18n/locales/server/web/en.json",
        "/app/i18n/locales/server/legacy/en.json",
        "/app/i18n/locales/server/mobile/en.json"
      ],
      domain: ""
    },
    {
      code: "id",
      name: "indonesian",
      files: [
        "/app/i18n/locales/id.json",
        "/app/i18n/locales/server/web/id.json",
        "/app/i18n/locales/server/legacy/id.json",
        "/app/i18n/locales/server/mobile/id.json"
      ]
    },
    {
      code: "th",
      name: "thai",
      files: [
        "/app/i18n/locales/th.json",
        "/app/i18n/locales/server/web/th.json",
        "/app/i18n/locales/server/legacy/th.json",
        "/app/i18n/locales/server/mobile/th.json"
      ]
    },
    {
      code: "zh",
      name: "chinese",
      files: [
        "/app/i18n/locales/zh.json",
        "/app/i18n/locales/server/web/zh.json",
        "/app/i18n/locales/server/legacy/zh.json",
        "/app/i18n/locales/server/mobile/zh.json"
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: false,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    name: "english",
    files: [
      {
        path: "/app/i18n/locales/en.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/web/en.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/legacy/en.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/mobile/en.json",
        cache: undefined
      }
    ],
    domain: ""
  },
  {
    code: "id",
    name: "indonesian",
    files: [
      {
        path: "/app/i18n/locales/id.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/web/id.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/legacy/id.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/mobile/id.json",
        cache: undefined
      }
    ]
  },
  {
    code: "th",
    name: "thai",
    files: [
      {
        path: "/app/i18n/locales/th.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/web/th.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/legacy/th.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/mobile/th.json",
        cache: undefined
      }
    ]
  },
  {
    code: "zh",
    name: "chinese",
    files: [
      {
        path: "/app/i18n/locales/zh.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/web/zh.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/legacy/zh.json",
        cache: undefined
      },
      {
        path: "/app/i18n/locales/server/mobile/zh.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/