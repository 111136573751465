import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hfkeyICBpmHFsBXhXmy2zKGRpTQOJIxftd2IEuNha5s from "/app/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import plugin_z7z1a6yg8I_zyThBqUBObV4FLqf2r0KIi0JDPHuedaQ from "/app/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk from "/app/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import initialize_locale_8Y7t9zWPM1cWQ3EkxU0FLFiBAPL9yru19kcZsNNEy_E from "/app/src/modules/i18n/runtime/plugins/initialize-locale.ts";
import gtm_hRx1l0KBF_WK0VZA_0XmjbFADXW_6Qc3g__I2p6hl_Q from "/app/src/modules/gtm/runtime/plugins/gtm.ts";
import server_flow_token_query_Zcs23PwJGHrYSYP6P5lAR_Ua_hp6DyHvQFwVXm9C1gg from "/app/src/modules/fetch/runtime/plugins/server-flow-token-query.ts";
import client_flow_token_query_JliwJi2TqSG4FQJHRcqnzfjEBtEO_kiN3gllZqwth0A from "/app/src/modules/fetch/runtime/plugins/client-flow-token-query.ts";
import fetch_provider_MfTPUfg9ZFjy9G47_Z9M5Fw8wcCQ8_uYmwVd8CP3zwc from "/app/src/modules/fetch/runtime/plugins/fetch-provider.ts";
import default_fetch_provider_EvIydHL3DTs6_tAbJrQvtIHPIjbm5qEcisMy8MxOt9k from "/app/src/modules/fetch/runtime/plugins/default-fetch-provider.ts";
import vue_router_warn_handler_q2Ttd4XRat11NwQ5CLH3jXtQQVfoQY6_4KrMPgugxLg from "/app/src/plugins/debug/vue-router-warn-handler.ts";
import global_route_guard_k84RViRZZN6oDvdW9Na_s0j2_sc4lciV4HzR7GFwHgU from "/app/src/plugins/global-route-guard.ts";
import repository_DGuAPW_u8qOYrBEca6l9JEhR3QPJN8YJxORR_seoT38 from "/app/src/plugins/repository.ts";
import vue_query_lBpTSoI5fm7Rf3JtpLcytc4Nol8xsMV0JViptdSe4qg from "/app/src/plugins/vue-query.ts";
import ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_hfkeyICBpmHFsBXhXmy2zKGRpTQOJIxftd2IEuNha5s,
  plugin_z7z1a6yg8I_zyThBqUBObV4FLqf2r0KIi0JDPHuedaQ,
  switch_locale_path_ssr_NflG9_QeVcJ1jVig0vCfxB_cZhpEMQ9U2ujRUiYbbVw,
  route_locale_detect__HPHJq3Jg7gwhwgKEI8tQavopSAjmrCSPXl9HgL2h9U,
  i18n_EI7LsD1KYQADczz5hrChviGQCdVM8yUkvFEZLJpmnvM,
  plugin_vz9iHc7_bD8KQzUq6ZprTRfi1svzocRN0YrDyAJWMCk,
  initialize_locale_8Y7t9zWPM1cWQ3EkxU0FLFiBAPL9yru19kcZsNNEy_E,
  gtm_hRx1l0KBF_WK0VZA_0XmjbFADXW_6Qc3g__I2p6hl_Q,
  server_flow_token_query_Zcs23PwJGHrYSYP6P5lAR_Ua_hp6DyHvQFwVXm9C1gg,
  client_flow_token_query_JliwJi2TqSG4FQJHRcqnzfjEBtEO_kiN3gllZqwth0A,
  fetch_provider_MfTPUfg9ZFjy9G47_Z9M5Fw8wcCQ8_uYmwVd8CP3zwc,
  default_fetch_provider_EvIydHL3DTs6_tAbJrQvtIHPIjbm5qEcisMy8MxOt9k,
  vue_router_warn_handler_q2Ttd4XRat11NwQ5CLH3jXtQQVfoQY6_4KrMPgugxLg,
  global_route_guard_k84RViRZZN6oDvdW9Na_s0j2_sc4lciV4HzR7GFwHgU,
  repository_DGuAPW_u8qOYrBEca6l9JEhR3QPJN8YJxORR_seoT38,
  vue_query_lBpTSoI5fm7Rf3JtpLcytc4Nol8xsMV0JViptdSe4qg,
  ssg_detect_IpHCGcQQ_IR5Rl99qyukWoMA9fJGfuTYyoksTzy81cs
]