import type { $Fetch } from 'nitropack'
import { RepositoryBuilder } from '~/plugins/repository/repository-builder'
import type { Activity } from '~/types/api/bff/web/booking-service/activity.types'
import type { BookingSlot } from '~/types/api/bff/web/booking-service/time-slots.types'
import type { AvailableSlotResponse } from '~/types/api/bff/web/booking-service/available-slots.types'
import type { User, UserCard } from '~/types/api/bff/web/sandbox/user.types'
import type { Question } from '~/types/api/bff/web/booking-service/questions.types.js'
import type {
  CreateReservationRequestBody,
  RescheduleReservationRequestBody,
  Reservation,
} from '~/types/api/bff/web/reservation-service/reservation.types'
import type { BeautyClasses } from '~/types/api/bff/web/booking-services/beauty-classes.types'
import type { BeautyServices } from '~/types/api/bff/web/booking-services/beauty-services.types'
import type { Activities } from '~/types/api/bff/web/layout/store-services/activities.types'
import type { ActivityGroups } from '~/types/api/bff/web/layout/store-services/activity-groups.types'
import type { Brands } from '~/types/api/bff/web/brands/brands'
import type { Categories } from '~/types/api/bff/web/categories/categories'
import type { LayoutDTO } from '~/types/api/bff/web/layout/layout.types'
import type { Pages } from '~/types/api/bff/web/booking-services/pages.types'
import type { ProductFilter } from '~/types/api/data/product/filter/filter.types'
import type { UserData } from '~/types/api/bff/web/luxola/user/user.types'
import type { WishlistedVariantIds } from '~/types/api/bff/web/wishlist/wishlist.types'
import type {
  SearchResult,
  PopularQueries,
} from '~/types/api/bff/web/search/search.types'
import type { CsProductDTO } from '~/types/api/bff/web/catalogue-service/product.types'
import type { AccountData } from '~/types/api/bff/web/luxola/account/account.types'
import type {
  BagDataDTO,
  BagItemsCountDTO,
} from '~/types/api/bff/web/cart/bag.types'
import type { CreateLineItemPayload } from '~/server/apps/web/v1/cart/line-items/controllers/create.controller'
import type { UpdateLineItemPayload } from '~/server/apps/web/v1/cart/line-items/controllers/update.controller'
import type { WaitlistedVariantIds } from '~/types/api/bff/web/waitlist/waitlist.types'
import type {
  NotificationSubscribedVariantIds,
  CreateNotificationSubscriptionPayload,
} from '~/types/api/bff/web/notification-subscription/remind-me.types'
import type { SocialAuthPayload } from '~/types/api/bff/web/auth/social-auth.types'
import type { ContentEntryDTO } from '~/types/api/bff/web/contentful/content-entry.types'
import type { AllUserSegment } from '~/types/api/bff/web/sea-falcon/all-user-segment/all-user-segment.types'
import type {
  FeaturedProductsConfigPayloadDTO,
  FeaturedProductsDTO,
} from '~/types/api/bff/web/featured-products/featured-products.types'
import type { OverlayDTO } from '~/types/api/bff/web/contentful/component.types'
import type {
  CartSamplesDTO,
  CartDTO,
  CartOffersDTO,
} from '~/types/api/bff/web/cart.types.ts'
import type { RewardsDTO } from '~/types/api/bff/web/rewards/rewards.types'
import type { beautyPassOffersDTO } from '~/types/api/bff/web/beauty-pass-offers/beauty-pass-offers.types'

export default defineNuxtPlugin({
  name: 'repository-plugin',
  dependsOn: ['default-fetch-provider-plugin', 'fetch-provider-plugin'],
  async setup() {
    const { $fetchProvider, $defaultFetchProvider } = useNuxtApp()
    const activitiesRepository = createActivitiesRepository(
      $defaultFetchProvider as $Fetch
    )
    const bagItemsCountRepository = createBagItemsCountRepository(
      $defaultFetchProvider as $Fetch
    )
    const bagLineItemsRepository = createBagLineItemsRepository(
      $defaultFetchProvider as $Fetch
    )
    const bagRepository = createBagRepository($defaultFetchProvider as $Fetch)
    const beautyPassOffersRepository = createBeautyPassOffersRepository(
      $defaultFetchProvider as $Fetch
    )
    const bookingQuestionsRepository = createBookingQuestionRepository(
      $defaultFetchProvider as $Fetch
    )
    const bookingReservationRepository = createBookingReservationRepository(
      $fetchProvider as $Fetch
    )
    const bookingServicesBeautyClassesRepository =
      createBookingServicesBeautyClassesRepository(
        $defaultFetchProvider as $Fetch
      )
    const bookingServicesBeautyServicesRepository =
      createBookingServicesBeautyServicesRepository(
        $defaultFetchProvider as $Fetch
      )
    const bookingServicesPagesRepository = createBookingServicesPagesRepository(
      $defaultFetchProvider as $Fetch
    )
    const storeServicesActivitiesRepository =
      createStoreServicesActivitiesRepository($defaultFetchProvider as $Fetch)
    const storeServicesActivityGroupsRepository =
      createStoreServicesActivityGroupsRepository(
        $defaultFetchProvider as $Fetch
      )
    const bookingTimeSlotRepository = createBookingTimeSlotRepository(
      $defaultFetchProvider as $Fetch
    )
    const bookingAvailabilityRepository = createAvailabilityRepository(
      $defaultFetchProvider as $Fetch
    )
    const brandRepository = createBrandRepository(
      $defaultFetchProvider as $Fetch
    )
    const cartOffersRepository = createCartOffersRepository(
      $defaultFetchProvider as $Fetch
    )
    const categoryRepository = createCategoryRepository(
      $defaultFetchProvider as $Fetch
    )
    const cartRepository = createCartRepository($defaultFetchProvider as $Fetch)
    const cartSamplesRepository = createCartSamplesRepository(
      $defaultFetchProvider as $Fetch
    )
    const layoutRepository = createLayoutRepository(
      $defaultFetchProvider as $Fetch
    )
    const luxolaRepository = createLuxolaRepository($fetchProvider as $Fetch)
    const luxolaUserAccountRepository = createLuxolaUserAccountRepository(
      $fetchProvider as $Fetch
    )
    const productsFiltersRepository = createProductsFiltersRepository(
      $defaultFetchProvider as $Fetch
    )
    const popularQueryRepository = createPopularQueryRepository(
      $defaultFetchProvider as $Fetch
    )
    const reservationRepository = createReservationRepository(
      $defaultFetchProvider as $Fetch
    )
    const rewardsRepository = createRewardsRepository(
      $defaultFetchProvider as $Fetch
    )
    const searchRepository = createSearchRepository(
      $defaultFetchProvider as $Fetch
    )
    const usersRepository = createSandboxUsersRepository(
      $fetchProvider as $Fetch
    )
    const waitlistRepository = createWailistRepository($fetchProvider as $Fetch)

    const notificationSubscriptionListRepository =
      createNotificationSubscriptionRepository(
        $fetchProvider as $Fetch,
        '/api/web/v1/notification-subscription/'
      )

    const notificationSubscriptionCreateRepository =
      createNotificationSubscriptionRepository(
        $fetchProvider as $Fetch,
        '/api/web/v1/notification-subscription/subscribe'
      )

    const wishlistRepository = createWishlistRepository(
      $fetchProvider as $Fetch
    )
    const variantsRepository = createVariantsRepository(
      $defaultFetchProvider as $Fetch
    )

    const sessionsRepository = createSessionsRepository(
      $fetchProvider as $Fetch
    )
    const socialAuthRepository = createSocialAuthRepository(
      $fetchProvider as $Fetch
    )

    const contentEntriesRepository = createContentEntriesRepository(
      $fetchProvider as $Fetch
    )

    const previewContentEntriesRepository =
      createPreviewContentEntriesRepository($fetchProvider as $Fetch)

    const previewOverlaysRepository = createPreviewOverlaysRepository(
      $fetchProvider as $Fetch
    )

    const seaFalconAllUserSegmentsRepository =
      createSeaFalconAllUserSegmentsRepository($fetchProvider as $Fetch)

    const featuredProductRepository = createFeaturedProductRepository(
      $fetchProvider as $Fetch
    )

    return {
      provide: {
        activitiesRepository,
        bagItemsCountRepository,
        bagLineItemsRepository,
        bagRepository,
        beautyPassOffersRepository,
        bookingAvailabilityRepository,
        bookingQuestionsRepository,
        bookingReservationRepository,
        bookingServicesBeautyClassesRepository,
        bookingServicesBeautyServicesRepository,
        bookingServicesPagesRepository,
        bookingTimeSlotRepository,
        brandRepository,
        cartOffersRepository,
        cartRepository,
        cartSamplesRepository,
        categoryRepository,
        contentEntriesRepository,
        featuredProductRepository,
        layoutRepository,
        luxolaRepository,
        luxolaUserAccountRepository,
        notificationSubscriptionCreateRepository,
        notificationSubscriptionListRepository,
        popularQueryRepository,
        previewContentEntriesRepository,
        previewOverlaysRepository,
        productsFiltersRepository,
        reservationRepository,
        rewardsRepository,
        seaFalconAllUserSegmentsRepository,
        searchRepository,
        sessionsRepository,
        storeServicesActivityGroupsRepository,
        storeServicesActivitiesRepository,
        socialAuthRepository,
        usersRepository,
        variantsRepository,
        waitlistRepository,
        wishlistRepository,
      },
    }
  },
})

function createActivitiesRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/booking-service/activities/'
  )
    .withIndex<Activity[]>()
    .withShow<Activity>()
    .build()
}

function createBagItemsCountRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/cart/items-count/')
    .withIndex<BagItemsCountDTO>()
    .build()
}

function createBagLineItemsRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/cart/line-items/')
    .withCreate<CreateLineItemPayload, BagDataDTO>()
    .withUpdate<Partial<UpdateLineItemPayload>, BagDataDTO>()
    .withDelete<BagDataDTO>()
    .build()
}

function createBagRepository(Provider: $Fetch) {
  return new RepositoryBuilder(Provider, '/api/web/v1/cart/bag/')
    .withIndex<BagDataDTO>()
    .build()
}

function createBookingQuestionRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/booking-service/questions/'
  )
    .withShow<Question>()
    .build()
}

function createBookingReservationRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/reservation-service/create'
  )
    .withCreate<CreateReservationRequestBody, Reservation>()
    .build()
}

function createBookingServicesBeautyClassesRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/booking-services/beauty-classes'
  )
    .withIndex<BeautyClasses>()
    .build()
}

function createBookingServicesBeautyServicesRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/booking-services/beauty-services'
  )
    .withIndex<BeautyServices>()
    .build()
}

function createBookingServicesPagesRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/booking-services/pages'
  )
    .withIndex<Pages>()
    .build()
}

function createStoreServicesActivitiesRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/layouts/store-services/activities'
  )
    .withIndex<Activities>()
    .build()
}

function createStoreServicesActivityGroupsRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/layouts/store-services/activity-groups'
  )
    .withIndex<ActivityGroups>()
    .build()
}

function createBookingTimeSlotRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/booking-service/time-slots/'
  )
    .withShow<BookingSlot[]>()
    .build()
}

function createAvailabilityRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/booking-service/available-slots/'
  )
    .withShow<AvailableSlotResponse>()
    .build()
}

function createBrandRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/brands/')
    .withIndex<Brands>()
    .build()
}

function createCartRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/cart/')
    .withIndex<CartDTO>()
    .build()
}

function createCartOffersRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/cart/offers')
    .withIndex<CartOffersDTO>()
    .build()
}

function createCategoryRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/categories/')
    .withIndex<Categories>()
    .build()
}

function createCartSamplesRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/cart/samples')
    .withIndex<CartSamplesDTO>()
    .build()
}

function createLayoutRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/layouts/')
    .withIndex<LayoutDTO>()
    .build()
}

function createLuxolaRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/user')
    .withIndex<UserData>()
    .build()
}

function createLuxolaUserAccountRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/account')
    .withIndex<AccountData>()
    .build()
}

function createProductsFiltersRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/products/filters/')
    .withIndex<ProductFilter>()
    .build()
}

function createPopularQueryRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/search/popular-queries/'
  )
    .withIndex<PopularQueries>()
    .build()
}

function createReservationRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/reservation-service/'
  )
    .withShow<Reservation>()
    .withCreate<RescheduleReservationRequestBody, Reservation>()
    .withDelete()
    .build()
}

function createRewardsRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/rewards/')
    .withIndex<RewardsDTO>()
    .build()
}

function createSandboxUsersRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/sandbox/users/')
    .withIndex<UserCard[]>()
    .withShow<User>()
    .withCreate<UserCard, UserCard>()
    .withUpdate<Partial<UserCard>, UserCard>()
    .withDelete<UserCard>()
    .build()
}

function createSearchRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/search/')
    .withIndex<SearchResult>()
    .build()
}

function createWailistRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/waitlist/')
    .withIndex<WaitlistedVariantIds>()
    .withUpdate<Partial<object>, WaitlistedVariantIds>()
    .build()
}

function createNotificationSubscriptionRepository(
  $fetchProvider: $Fetch,
  basePath: string
) {
  return new RepositoryBuilder($fetchProvider, basePath)
    .withIndex<NotificationSubscribedVariantIds>()
    .withCreate<CreateNotificationSubscriptionPayload, string>()
    .build()
}

function createWishlistRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/wishlist/')
    .withIndex<WishlistedVariantIds>()
    .withUpdate<Partial<object>, WishlistedVariantIds>()
    .build()
}

function createVariantsRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/variants')
    .withIndex<CsProductDTO>()
    .build()
}

function createSessionsRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/sessions')
    .withDelete()
    .build()
}

function createSocialAuthRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/auth/social')
    .withCreate<SocialAuthPayload, AccountData>()
    .build()
}

function createContentEntriesRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/contentful/content-entries/'
  )
    .withShow<ContentEntryDTO>()
    .build()
}

function createPreviewContentEntriesRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/contentful/preview/content-entries/'
  )
    .withShow<ContentEntryDTO>()
    .build()
}

function createPreviewOverlaysRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/preview/overlays/')
    .withShow<OverlayDTO>()
    .build()
}

function createSeaFalconAllUserSegmentsRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/sea-falcon/all-user-segments/'
  )
    .withIndex<AllUserSegment[]>()
    .build()
}

function createFeaturedProductRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder($fetchProvider, '/api/web/v1/featured-products/')
    .withCreate<FeaturedProductsConfigPayloadDTO, FeaturedProductsDTO>()
    .build()
}

function createBeautyPassOffersRepository($fetchProvider: $Fetch) {
  return new RepositoryBuilder(
    $fetchProvider,
    '/api/web/v1/beauty-pass-offers/'
  )
    .withIndex<beautyPassOffersDTO>()
    .build()
}
